import { ReferenceManyField, ReferenceManyFieldProps } from 'react-admin'

export const SubcollectionReferenceManyField = ({
	subcollectionId,
	target: targetProp,
	...props
}: Omit<ReferenceManyFieldProps, 'reference'> & {
	subcollectionId: string
}) => {
	const { record, resource } = props

	const reference = `${resource ?? ''}:${subcollectionId}`

	return <ReferenceManyField reference={reference} target={targetProp} {...props} />
}
