import {
	ArrayField,
	ArrayInput,
	ChipField,
	Create,
	CreateProps,
	Datagrid,
	DateField,
	DateTimeInput,
	Edit,
	EditButton,
	EditProps,
	List,
	ListProps,
	NumberField,
	NumberInput,
	required,
	SimpleForm,
	SimpleFormIterator,
	TextField,
	TextInput,
} from 'react-admin'

import { useCommonStyles } from 'styles/commonStyles'

export const PollList = (props: ListProps) => (
	<List {...props} sort={{ field: 'createdAt', order: 'DESC' }} exporter={false}>
		<Datagrid className={useCommonStyles().header}>
			<DateField source="createdAt" showTime className={useCommonStyles().textNoWrap} />

			<TextField source="title" />
			<ArrayField source="choices">
				<Datagrid>
					<ChipField source="name" />
					<ChipField source="voteCount" />
				</Datagrid>
			</ArrayField>

			<NumberField source="totalVoteCount" />
			<NumberField source="commentCount" />

			<EditButton />
		</Datagrid>
	</List>
)

const pollChoicesValidator = [
	required(),
	(val: any[]) => (val?.length < 2 ? '최소 2개 이상의 투표 항목이 필요합니다.' : undefined),
]

export const PollEdit = (props: EditProps) => (
	<Edit {...props}>
		<SimpleForm>
			<TextInput source="id" disabled />
			<DateTimeInput source="createdAt" validate={required()} />

			<TextInput source="title" validate={required()} />
			<ArrayInput source="choices" validate={pollChoicesValidator}>
				<SimpleFormIterator>
					<TextInput source="name" validate={required()} />
					<NumberInput source="voteCount" validate={required()} />
				</SimpleFormIterator>
			</ArrayInput>

			<NumberInput source="totalVoteCount" validate={required()} />
			<NumberInput source="commentCount" validate={required()} />
		</SimpleForm>
	</Edit>
)

export const PollCreate = (props: CreateProps) => (
	<Create {...props}>
		<SimpleForm>
			<DateTimeInput source="createdAt" validate={required()} defaultValue={new Date()} />

			<TextInput source="title" validate={required()} />
			<ArrayInput source="choices" validate={pollChoicesValidator}>
				<SimpleFormIterator>
					<TextInput source="name" validate={required()} />
					<NumberInput source="voteCount" validate={required()} defaultValue={0} />
				</SimpleFormIterator>
			</ArrayInput>

			<NumberInput source="totalVoteCount" validate={required()} min={0} defaultValue={0} />
			<NumberInput source="commentCount" validate={required()} defaultValue={0} />
		</SimpleForm>
	</Create>
)
