import { sanitizeFieldRestProps, TextFieldProps } from 'react-admin'

import { Typography } from '@material-ui/core'

export const RawTextField = ({ value, ...props }: TextFieldProps & { value: unknown }) => {
	const { className, source, emptyText, ...rest } = props
	return (
		<Typography
			component="span"
			variant="body2"
			className={className}
			{...sanitizeFieldRestProps(rest)}
		>
			{value != null && typeof value !== 'string' ? JSON.stringify(value) : value || emptyText}
		</Typography>
	)
}
