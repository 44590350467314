import { CheckboxGroupInput, CheckboxGroupInputProps } from 'react-admin'

import { EWorkDay } from '@jleem99/workinfo-models'

import { useEnumChoices } from 'hooks/useEnumChoices'

const WorkDaysInput = (props: CheckboxGroupInputProps) => (
	<CheckboxGroupInput choices={useEnumChoices(EWorkDay)} {...props} />
)

export default WorkDaysInput
