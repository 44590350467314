import {
	Datagrid,
	Edit,
	EditButton,
	EditProps,
	List,
	ListProps,
	SaveButton,
	SimpleForm,
	TextField,
	TextInput,
	Toolbar,
	ToolbarProps,
} from 'react-admin'

import { ArrayTextInput } from 'components/input/ArrayTextInput'
import { useCommonStyles } from 'styles/commonStyles'

export const ConfigList = (props: ListProps) => (
	<List {...props} exporter={false} bulkActionButtons={false}>
		<Datagrid className={useCommonStyles().header}>
			<TextField source="configName" />
			<EditButton />
		</Datagrid>
	</List>
)

export const ConfigEdit = (props: EditProps) => (
	<Edit {...props}>
		<SimpleForm toolbar={<ConfigEditToolbar />}>
			<TextInput source="configName" disabled />
			<ArrayTextInput source="phoneNumbers" label="상담사 휴대폰 번호" />
		</SimpleForm>
	</Edit>
)

const ConfigEditToolbar = (props: ToolbarProps) => (
	<Toolbar {...props}>
		<SaveButton />
	</Toolbar>
)
