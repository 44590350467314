import { ChipField, FieldProps, ReferenceField } from 'react-admin'

import { resourceTranslation } from 'i18nProvider'

export const UserReferenceField = ({ source = 'userId', ...props }: FieldProps) => {
	return (
		<ReferenceField reference="users" source={source} {...props}>
			<ChipField source="name" />
		</ReferenceField>
	)
}

/**
 * 라벨은 Datagrid 등의 상위 컴포넌트가 하위 컴포넌트의 prop을 검사하여 추가되므로
 * 커스텀 필드에 라벨들 넣기 위해서는 다음과 같이 지정해주어야 함
 */
UserReferenceField.defaultProps = {
	label: resourceTranslation.resources.users.name,
	addLabel: true,
}
