import { Card, CardContent, CardHeader, createTheme, ThemeProvider } from '@material-ui/core'

const theme = createTheme({
	overrides: {
		MuiCardHeader: {
			root: {},
			title: {
				color: '#272727',
				fontSize: 50,
				fontWeight: 800,
				textAlign: 'center',
			},
		},
		MuiCardContent: {
			root: {
				fontSize: 24,
				fontWeight: 500,
				textAlign: 'center',
			},
		},
	},
})

export const Dashboard = () => (
	<ThemeProvider theme={theme}>
		<Card>
			<CardHeader title="Workinfo Admin" />
			<CardContent>Workinfo 어드민 페이지입니다.</CardContent>
		</Card>
	</ThemeProvider>
)
