import React from 'react'
import {
	ArrayInput,
	InputProps,
	required,
	SelectArrayInput,
	SelectInput,
	SimpleFormIterator,
	TextInput,
	useRecordContext,
} from 'react-admin'

import { EQuestionType, FormQuestion, FormQuestionSelect } from '@jleem99/workinfo-models'
import { get } from 'lodash'

import { RARecord } from 'utils/types'

export const ConsultFormInput = (props: InputProps) => {
	const source = props.source ?? ''

	return (
		<React.Fragment>
			<TextInput label="폼 이름" source={`${source}.name`} disabled fullWidth />
			<ArrayInput label="" source={`${source}.questions`} validate={required()} disabled>
				<SimpleFormIterator>
					<QuestionInput source="" />
				</SimpleFormIterator>
			</ArrayInput>
		</React.Fragment>
	)
}

const QuestionInput = (props: InputProps) => {
	const record = useRecordContext<RARecord<FormQuestion>>()

	const { source } = props

	const question = get(record, source) as FormQuestion

	switch (question.type) {
		case EQuestionType.TextInput:
			return (
				<TextInput
					source={`${source}.textInput`}
					label={question.shortTitle}
					validate={required()}
					fullWidth
				/>
			)

		case EQuestionType.SingleSelect:
			return (
				<SelectInput
					label={question.shortTitle}
					source={source}
					choices={question.choices.map((choice) => ({
						id: choice.text,
						name: choice.text,
						disabled: true,
					}))}
					format={(question_: FormQuestionSelect) => {
						console.log('formatValue', question_)
						return question_.choices.find((choice) => choice.isSelected)?.text
					}}
					fullWidth
				/>
			)

		case EQuestionType.MultiSelect:
			return (
				<SelectArrayInput
					label={question.shortTitle}
					source={source}
					choices={question.choices.map((choice) => ({
						id: choice.text,
						name: choice.text,
						disabled: true,
					}))}
					format={(question_: FormQuestionSelect) => {
						console.log('formatValue', question_)
						return question_.choices
							.filter((choice) => choice.isSelected)
							.map((choice) => choice.text)
					}}
					fullWidth
				/>
			)

		default:
			return null
	}
}
