import {
	BooleanField,
	BooleanInput,
	ChipField,
	Create,
	CreateProps,
	Datagrid,
	DateField,
	DateTimeInput,
	Edit,
	EditButton,
	EditProps,
	FormDataConsumer,
	List,
	ListProps,
	ReferenceField,
	ReferenceInput,
	required,
	SelectField,
	SelectInput,
	SimpleForm,
	TextField,
	TextInput,
} from 'react-admin'
import { useForm } from 'react-final-form'

import { NotificationDocument } from '@jleem99/workinfo-models'

import { useCommonStyles } from 'styles/commonStyles'

export const NotificationList = (props: ListProps) => (
	<List {...props} sort={{ field: 'createdAt', order: 'DESC' }} exporter={false}>
		<Datagrid className={useCommonStyles().header}>
			<DateField source="createdAt" showTime className={useCommonStyles().textNoWrap} />

			<ReferenceField label="resources.users.name" source="recipientId" reference="users">
				<ChipField source="name" />
			</ReferenceField>
			<TextField source="title" />
			<TextField source="text" />
			<SelectField source="type" choices={choices} />

			<BooleanField source="isGlobal" />

			<EditButton />
		</Datagrid>
	</List>
)

export const NotificationEdit = (props: EditProps) => (
	<Edit {...props}>
		<SimpleForm>
			<TextInput source="id" disabled />

			<DateTimeInput source="createdAt" validate={required()} />

			<BooleanInput source="isGlobal" validate={required()} />
			<RecipientInput />

			<TextInput source="title" validate={required()} />
			<TextInput source="text" validate={required()} />
			{/* <StorageImageInput source="image" validate={undefined} /> */}

			<SelectInput source="type" choices={choices} validate={required()} />
			<FormDataConsumer fullWidth>
				{({ formData, ...rest }) => {
					const document = formData as NotificationDocument

					switch (document.type) {
						case 'event':
							return <TextInput source="eventUrl" validate={required()} {...rest} />
						case 'community-action':
							return (
								<ReferenceInput source="postId" reference="communityPosts" {...rest}>
									<SelectInput optionText="body" validate={required()} />
								</ReferenceInput>
							)
						case 'consult-progress':
							return <TextInput source="chatUrl" validate={required()} {...rest} />
						case 'magazine':
							return (
								<ReferenceInput source="magazineId" reference="magazines" {...rest}>
									<SelectInput optionText="title" validate={required()} />
								</ReferenceInput>
							)
					}
				}}
			</FormDataConsumer>
		</SimpleForm>
	</Edit>
)

const RecipientInput = (props: any) => {
	const form = useForm()

	return (
		<FormDataConsumer fullWidth {...props}>
			{({ formData, ...rest }) => {
				const document = formData as NotificationDocument
				if (document.isGlobal && document.recipientId !== null) form.change('recipientId', null)
				if (document.isGlobal) return null
				return (
					<ReferenceInput
						label="resources.users.name"
						source="recipientId"
						reference="users"
						{...rest}
					>
						<SelectInput optionText="name" validate={document.isGlobal ? undefined : required()} />
					</ReferenceInput>
				)
			}}
		</FormDataConsumer>
	)
}

const choices = [
	{ id: 'community-action', name: '커뮤니티 액션' },
	{ id: 'consult-progress', name: '상담 진행' },
	{ id: 'event', name: '이벤트' },
	{ id: 'magazine', name: '매거진 소식' },
]

export const NotificationCreate = (props: CreateProps) => (
	<Create {...props}>
		<SimpleForm>
			<DateTimeInput source="createdAt" validate={required()} defaultValue={new Date()} />

			<BooleanInput source="isGlobal" validate={required()} defaultValue={false} />
			<RecipientInput />

			<TextInput source="title" validate={required()} />
			<TextInput source="text" validate={required()} />
			{/* <StorageImageInput source="image" validate={undefined} /> */}

			<SelectInput source="type" choices={choices} validate={required()} />
			<FormDataConsumer fullWidth>
				{({ formData, ...rest }) => {
					const document = formData as NotificationDocument

					switch (document.type) {
						case 'event':
							return <TextInput source="eventUrl" validate={required()} {...rest} />
						case 'community-action':
							return (
								<ReferenceInput source="postId" reference="communityPosts" {...rest}>
									<SelectInput optionText="body" validate={required()} />
								</ReferenceInput>
							)
						case 'consult-progress':
							return <TextInput source="chatUrl" validate={required()} {...rest} />
						case 'magazine':
							return (
								<ReferenceInput source="magazineId" reference="magazines" {...rest}>
									<SelectInput optionText="title" validate={required()} />
								</ReferenceInput>
							)
					}
				}}
			</FormDataConsumer>
		</SimpleForm>
	</Create>
)
