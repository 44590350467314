import { Admin, Resource } from 'react-admin'

import CommunityIcon from '@material-ui/icons/ArtTrack'
import ReportIcon from '@material-ui/icons/Flag'
import UserIcon from '@material-ui/icons/Group'
import ConsultIcon from '@material-ui/icons/HeadsetMic'
import VoteIcon from '@material-ui/icons/HowToVote'
import NotificationIcon from '@material-ui/icons/Notifications'
import MagazineIcon from '@material-ui/icons/PostAdd'
import SettingsIcon from '@material-ui/icons/Settings'
import GlobalIcon from '@material-ui/icons/Web'
import WorkIcon from '@material-ui/icons/Work'

import { Dashboard } from 'components/dashboard/Dashboard'
import {
	CommunityPostCreate,
	CommunityPostEdit,
	CommunityPostList,
} from 'components/resource/CommunityPostResource'
import { ConfigEdit, ConfigList } from 'components/resource/ConfigResource'
import { ConsultEdit, ConsultList } from 'components/resource/ConsultResource'
import { GlobalCreate, GlobalEdit, GlobalList } from 'components/resource/GlobalResource'
import {
	JobPostingCreate,
	JobPostingEdit,
	JobPostingList,
} from 'components/resource/JobPostingResource'
import { MagazineCreate, MagazineEdit, MagazineList } from 'components/resource/MagazineResource'
import {
	NotificationCreate,
	NotificationEdit,
	NotificationList,
} from 'components/resource/NotificationResource'
import { PollCreate, PollEdit, PollList } from 'components/resource/PollResource'
import { ReportList } from 'components/resource/ReportResource'
import { UserEdit, UserList } from 'components/resource/UserResource'
import { i18nProvider } from 'i18nProvider'
import { authProvider, dataProvider } from 'services/provider'
// import 'styles/reset.css'
import 'styles/index.css'
import { theme } from 'theme'

// prettier-ignore
function App() {
	return (
		<Admin
			dashboard={Dashboard}
			theme={theme}
			authProvider={authProvider}
			dataProvider={dataProvider}
			i18nProvider={i18nProvider}
		>
			<Resource
				name="users"
				list={UserList}
				edit={UserEdit}
				icon={UserIcon}
			/>
			<Resource
				name="jobPostings"
				list={JobPostingList}
				edit={JobPostingEdit}
				create={JobPostingCreate}
				icon={WorkIcon}
			/>
			<Resource
				name="communityPosts"
				list={CommunityPostList}
				edit={CommunityPostEdit}
				create={CommunityPostCreate}
				icon={CommunityIcon}
			/>
			<Resource
				name="consults"
				list={ConsultList}
				edit={ConsultEdit}
				// show={ConsultShow}
				icon={ConsultIcon}
			/>
			<Resource
				name="polls"
				list={PollList}
				edit={PollEdit}
				create={PollCreate}
				icon={VoteIcon}
			/>
			<Resource
				name="communityPosts:comments"
			/>
			<Resource
				name="magazines"
				list={MagazineList}
				edit={MagazineEdit}
				create={MagazineCreate}
				icon={MagazineIcon}
			/>
			<Resource
				name="notifications"
				list={NotificationList}
				edit={NotificationEdit}
				create={NotificationCreate}
				icon={NotificationIcon}
			/>
			<Resource
				name="reports"
				list={ReportList}
				icon={ReportIcon}
			/>
			<Resource
				name="globals"
				list={GlobalList}
				edit={GlobalEdit}
				create={GlobalCreate}
				icon={GlobalIcon}
			/>
			<Resource
				name="configs"
				list={ConfigList}
				edit={ConfigEdit}
				icon={SettingsIcon}
			/>
		</Admin>
	)
}

export default App
