/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
	ArrayInput,
	Create,
	CreateProps,
	Datagrid,
	DateField,
	DateTimeInput,
	Edit,
	EditButton,
	EditProps,
	FormDataConsumer,
	List,
	ListProps,
	required,
	SelectInput,
	SimpleForm,
	SimpleFormIterator,
	TextField,
	TextInput,
} from 'react-admin'

import { StorageImageInput } from 'components/input/StorageImageInput'
import { useCommonStyles } from 'styles/commonStyles'

export const MagazineList = (props: ListProps) => (
	<List {...props} sort={{ field: 'createdAt', order: 'DESC' }} exporter={false}>
		<Datagrid className={useCommonStyles().header} rowClick="edit">
			<DateField source="createdAt" showTime className={useCommonStyles().textNoWrap} />
			<TextField source="title" />
			<EditButton />
		</Datagrid>
	</List>
)

const magazineElementTypeChoices = [
	{ id: 'h1', name: '헤더 1' },
	{ id: 'h2', name: '헤더 2' },
	{ id: 'h3', name: '헤더 3' },
	{ id: 'image', name: '이미지' },
]

export const MagazineEdit = (props: EditProps) => (
	<Edit {...props}>
		<SimpleForm>
			<TextInput source="id" disabled />

			<DateTimeInput source="createdAt" validate={required()} />

			<TextInput source="title" validate={required()} />
			<StorageImageInput source="imageUri" />

			<ArrayInput source="body" validate={required()}>
				<SimpleFormIterator>
					<SelectInput source="type" choices={magazineElementTypeChoices} validate={required()} />
					<FormDataConsumer fullWidth>
						{({ formData, scopedFormData, getSource, ...rest }) =>
							scopedFormData?.type === 'image' ? (
								<StorageImageInput source={getSource?.('src') ?? 'src'} {...rest} label="이미지" />
							) : (
								<TextInput
									source={getSource?.('text') ?? 'text'}
									validate={required()}
									{...rest}
									label="텍스트"
									multiline
								/>
							)
						}
					</FormDataConsumer>
				</SimpleFormIterator>
			</ArrayInput>
		</SimpleForm>
	</Edit>
)

export const MagazineCreate = (props: CreateProps) => (
	<Create {...props}>
		<SimpleForm>
			<DateTimeInput source="createdAt" validate={required()} defaultValue={new Date()} />

			<TextInput source="title" validate={required()} />
			<StorageImageInput source="imageUri" />

			<ArrayInput source="body" validate={required()}>
				<SimpleFormIterator>
					<SelectInput source="type" choices={magazineElementTypeChoices} validate={required()} />
					<FormDataConsumer fullWidth>
						{({ formData, scopedFormData, getSource, ...rest }) =>
							scopedFormData?.type === 'image' ? (
								<StorageImageInput source={getSource?.('src') ?? 'src'} {...rest} label="이미지" />
							) : (
								<TextInput
									source={getSource?.('text') ?? 'text'}
									validate={required()}
									{...rest}
									label="텍스트"
									multiline
								/>
							)
						}
					</FormDataConsumer>
				</SimpleFormIterator>
			</ArrayInput>
		</SimpleForm>
	</Create>
)
