import { FirebaseAuthProvider, FirebaseDataProvider, RAFirebaseOptions } from 'react-admin-firebase'

const options: RAFirebaseOptions = {
	logging: false,
	// watch: ['users', 'jobPostings', 'polls', 'communityPosts', 'consults', 'formSubmissions'],
	persistence: 'local',
	disableMeta: true,
	lazyLoading: { enabled: true }, // https://github.com/benwinding/react-admin-firebase/issues/59 참고
	firestoreCostsLogger: {
		enabled: true,
	},
	dontAddIdFieldToDoc: true,
}

export const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

export const defaultDataProvider = FirebaseDataProvider(firebaseConfig, options)
export const defaultAuthProvider = FirebaseAuthProvider(firebaseConfig, options)
