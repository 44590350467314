import { useEffect } from 'react'
import { ChoicesInputProps, InputProps, ReferenceInput, required, SelectInput } from 'react-admin'
import { useForm } from 'react-final-form'

import { UserDocument } from '@jleem99/workinfo-models'

const OFFICIAL_UID = 'kqlMMKz2Hmfz9O9HOlLfBQNab202'

export interface UserReferenceInputProps extends InputProps {
	optionText?: string
}

/**
 * 유저 ID를 입력하는 레퍼런스 인풋
 */
export const UserReferenceInput = ({ optionText = 'name', ...props }: UserReferenceInputProps) => {
	return (
		<ReferenceInput
			label="resources.users.name"
			reference="users"
			defaultValue={OFFICIAL_UID}
			{...props}
		>
			<SelectInput optionText={optionText} />
		</ReferenceInput>
	)
}

/**
 * 유저 ID / 유저네임을 동시에 입력하는 레퍼런스 인풋
 */
export const UserAndUsernameReferenceInput = ({
	optionText = 'name',
	...props
}: Omit<UserReferenceInputProps, 'source'>) => {
	return (
		<ReferenceInput
			label="resources.users.name"
			source="userId"
			reference="users"
			defaultValue={OFFICIAL_UID}
			{...props}
		>
			<UserAndUsernameReference />
		</ReferenceInput>
	)
}

const UserAndUsernameReference = ({ choices, ...props }: ChoicesInputProps) => {
	const form = useForm()
	const userId = (form.getFieldState('userId')?.value as string) ?? ''
	const user = (choices as UserDocument[])?.find(
		(choice) => (choice as { id: string }).id === userId,
	)

	useEffect(() => {
		form.change('username', user?.name)
	}, [form, user?.name])

	return <SelectInput {...props} choices={choices} optionText="name" validate={required()} />
}
