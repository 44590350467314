import { makeStyles } from '@material-ui/core'

export const useCommonStyles = makeStyles({
	textWrap: {
		overflow: 'hidden',
		'text-overflow': 'ellipsis',
		display: '-webkit-box',
		'-webkit-line-clamp': 5,
		'line-clamp': 5,
		'-webkit-box-orient': 'vertical',
	},
	header: {
		'& .MuiTableHead-root': {
			whiteSpace: 'nowrap',
		},
	},
	textNoWrap: {
		whiteSpace: 'nowrap',
	},
})
