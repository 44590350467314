import { TextFieldProps, useRecordContext } from 'react-admin'

import i18n_countries from 'i18n-iso-countries'
import i18n_countries_locale_kr from 'i18n-iso-countries/langs/ko.json'
import { get } from 'lodash'

import { RawTextField } from './RawTextField'
i18n_countries.registerLocale(i18n_countries_locale_kr)

export const NationalityField = (props: TextFieldProps) => {
	const { className, source, emptyText, ...rest } = props
	const record = useRecordContext(props)
	const rawValue = source ? (get(record, source) as unknown) : ''

	const value = typeof rawValue === 'string' ? i18n_countries.getName(rawValue, 'ko') : rawValue

	return <RawTextField {...props} value={value} />
}
