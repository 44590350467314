/* eslint-disable @typescript-eslint/ban-ts-comment */
import { CastProperty } from '@jleem99/workinfo-models'
import firebase from 'firebase'
import { cloneDeep, cloneDeepWith } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import { RARecord } from 'utils/types'

export type DocumentWithFile<Document> = CastProperty<Document, string[], (string | File)[]>

class ImageUploader {
	/**
	 * @param directoryRef 해당 디렉토리 아래에 uuidv4로 생성된 이름으로 파일 업로드
	 * @return 업로드된 이미지의 다운로드 URL
	 */
	public async uploadFileToDir(
		file: File,
		directoryRef: firebase.storage.Reference,
	): Promise<string> {
		const path = directoryRef.child(uuidv4())
		await path.put(file)
		return (await path.getDownloadURL()) as string
	}

	/**
	 * 인자로 받은 객체에서 File 인스턴스를 값으로 가지는 프로퍼티들에 대해 인자로 주어진 경로로 업로드하는 함수
	 * @param document File 프로퍼티로서 업로드할 파일들을 담고 있는 객체
	 * @returns `document`에서 File 프로퍼티가 업로드된 파일의 다운로드 URL로 대치된 객체
	 *
	 * @example
	 * 	const document = {
	 * 		title: 'My Document',
	 * 		images: [file1, file2]
	 * 	}
	 *
	 * 	const uploadedDocument =
	 * 		await ImageUploader.uploadFilesFromDocument(document)
	 * 	// {
	 * 	// 	title: 'My Document',
	 * 	// 	images: [
	 * 	// 		'https://example.com/path/to/file1',
	 * 	// 		'https://example.com/path/to/file2'
	 * 	// 	]
	 * 	// }
	 */
	public async uploadFilesFromDocument<T extends RARecord, U extends DocumentWithFile<T>>(
		document: U,
		dirname: string, // GCS prefix
	) {
		const dir = firebase.storage().ref(dirname)
		const uploadFn = (file: File) => this.uploadFileToDir(file, dir)

		const hydratedDocument = cloneDeep(document)
		const promises: Promise<string>[] = []

		// document의 File 프로퍼티들에 대해 uploadFn을 호출하고,
		// uploadFn promise의 then 구문을 통해 promise resolve시
		// hydratedDocument의 프로퍼티(object[key])에 대치되도록 한다.
		// (cloneDeepWith의 리턴값은 사용하지 않음)
		cloneDeepWith(hydratedDocument, (value, key, object) => {
			if (value instanceof File) {
				const promise = uploadFn(value)
				promises.push(promise)
				// @ts-ignore
				void promise.then((downloadUrl) => (object[key] = downloadUrl))
			}
		})

		// 위의 cloneDeepWith customizer에서 생성된 promise들을 await한다.
		// 모든 promise들이 await 된 뒤에는 hydratedDocument의
		// File 프로퍼티들이 다운로드 URL로 대치가 된다.
		await Promise.all(promises)

		return hydratedDocument
	}
}

export default new ImageUploader()
