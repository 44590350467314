import polyglotI18nProvider from 'ra-i18n-polyglot'
import koreanMessages from 'ra-language-korean'

import type { ResourceTranslation } from './types'

export const resourceTranslation: ResourceTranslation = {
	resources: {
		users: {
			name: '유저',
			fields: {
				id: '유저 ID',
				name: '이름',
				nationality: '국적',
				gender: '성별',
				birthAt: '생년월일',
				currentVisa: '현재비자',
				currentVisaExpiresAt: '현재비자 만료일',
				address: '거주지',
				phoneNumber: '휴대폰 번호',
				jobSearchHistory: '구인구직 검색기록',
				communitySearchHistory: '커뮤니티 검색기록',
				hasAgreedToMarketing: '마케팅 동의',
				hasAgreedToPushNotification: '푸시 동의',
				createdAt: '생성일자',
				pushToken: '푸시 토큰',
			},
		},
		jobPostings: {
			name: '구인구직',
			fields: {
				id: '글 ID',
				userId: '유저 ID',
				companyName: '회사명',
				address: '주소',
				detailedAddress: '상세주소',
				normalizedRegion: '정규화 지역',
				phoneNumber: '전화번호',
				images: '이미지',
				businessType: '업종',
				workDays: '근무일',
				isWorkDaysAdjustable: '근무요일 조정가능',
				workStartTime: '근무 시작시간',
				workEndTime: '근무 종료시간',
				isWorktimeAdjustable: '근무시간 조정가능',
				payType: '급여 형태',
				payAmount: '급여액',
				isPayAdjustable: '급여 조정가능',
				recruitStartDate: '구인 시작일',
				recruitEndDate: '구인 종료일',
				recruitQuantity: '모집인원',
				recruitGender: '모집성별',
				recruitVisaTypes: '모집비자',
				postTitle: '제목',
				postBody: '상세내용',
				updatedAt: '업데이트',
				isEnded: '구인 종료여부',
				isPlusJobPosting: '플러스 채용여부',

				tags: '선호조건',

				geolocation: '주소 좌표',
				normalizedRegionDepth1: '정규화 지역 (Depth 1)',
			},
		},
		communityPosts: {
			name: '커뮤니티 포스트',
			fields: {
				id: '포스트 ID',
				userId: '유저 ID',
				username: '유저 이름',
				category: '카테고리',
				body: '내용',
				images: '이미지',
				commentCount: '댓글 수',
				likeCount: '좋아요 수',
				createdAt: '생성일자',
			},
		},
		consults: {
			name: '상담',
			fields: {
				id: '상담 ID',
				userId: '유저 ID',
				submittedAt: '제출일자',

				progress: '진행상황',
				'progress.currentStepIndex': '현재 진행단계 (0부터 시작)',
				'progress.steps': '진행단계',
				name: '단계 이름', // progress.steps[].name
				processedAt: '단계 처리시간', // progress.steps[].processedAt

				basicForm: '기본 폼',
				additionalForm: '추가 폼',

				currentVisa: '현재 비자',
				applyVisa: '신청 비자',

				// 'name': '폼 이름',
				// 'questions': '폼 질문',

				// 'title': '질문', // questions[].title
				// 'choices': '질문 항목', // questions[].choices
				// 'isSelected': '체크', // questions[].choices[].isSelected
				// 'text': '질문 선지', // questions[].choices[].text
			},
		},
		polls: {
			name: '투표',
			fields: {
				id: '투표 ID',
				title: '투표 제목',
				choices: '투표 항목',
				name: '항목 이름', // choices[].name
				voteCount: '항목 투표수', // choices[].voteCount
				totalVoteCount: '투표 수',
				commentCount: '댓글 수',
				createdAt: '생성일자',
			},
		},
		'communityPosts:comments': {
			name: '댓글',
			fields: {
				id: '댓글 ID',
				userId: '유저 ID',
				username: '유저 이름',
				body: '내용',
				createdAt: '생성일',
				likeCount: '좋아요 수',
			},
		},
		magazines: {
			name: '매거진',
			fields: {
				id: '매거진 ID',
				title: '매거진 제목',
				imageUri: '매거진 이미지',

				body: '본문',
				type: '타입',
				text: '텍스트',
				src: '이미지',

				createdAt: '생성일자',
			},
		},
		reports: {
			name: '신고',
			fields: {
				id: '신고 ID',
				reportType: '신고 타입',
				postType: '포스트 타입',
				postId: '포스트 ID',
				commentId: '댓글 ID',
				reportedAt: '신고일자',
				reporterUserId: '신고 유저',
				reportedUserId: '피신고 유저',
			},
		},
		notifications: {
			name: '알림',
			fields: {
				id: '알림 ID',
				createdAt: '생성일자',
				image: '이미지',
				isGlobal: '전체 알림 여부',
				recipientId: '수신자 ID',
				title: '제목',
				text: '본문',
				type: '알림 종류',
				unread: '읽음 여부',

				eventUrl: '이벤트 URL',
				actionType: '액션 타입',
				postId: '포스트 ID',
				consultId: '상담 ID',
				chatUrl: '상담 URL',
				magazineId: '매거진 ID',
			},
		},
		globals: {
			name: '전역 설정 · 배너',
			fields: {
				id: '전역 설정 버전 ID',
				banner: '배너',
				'banner.mainSwipeBanner': '메인페이지 스와이프 배너',
				'banner.mainStaticBanner': '메인페이지 스태틱 배너',
				'banner.recruitSwipeBanner': '구인구직 스와이프 배너',
				'banner.communityBanner': '커뮤니티 투표 배너',
				'banner.mypageSwipeBanner': '마이페이지 스와이프 배너',
				imgSrc: '배너 이미지',
				url: '배너 링크',

				poll: '투표 설정',
				'poll.gradient': '투표 그래디언트 색상 (상단, 하단)',
				'poll.backgroundImage': '투표 배경 이미지',

				createdAt: '버전 생성일자',
			},
		},
		configs: {
			name: '기타 내부 설정',
			fields: {
				configName: '설정 이름',
			},
		},
	},
}

export const i18nProvider = polyglotI18nProvider(
	(locale) => Object.assign(koreanMessages, resourceTranslation),
	'ko',
	{ allowMissing: true },
)
