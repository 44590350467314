import { SelectInput, SelectInputProps, SelectArrayInput, SelectArrayInputProps } from 'react-admin'

import { Enum } from '@jleem99/workinfo-models'

import { useEnumChoices } from 'hooks/useEnumChoices'

export const EnumSelectInput = ({ enumType, ...props }: SelectInputProps & { enumType: Enum }) => (
	<SelectInput choices={useEnumChoices(enumType)} {...props} />
)

export const EnumArrayInput = ({
	enumType,
	...props
}: SelectArrayInputProps & { enumType: Enum }) => (
	<SelectArrayInput choices={useEnumChoices(enumType)} {...props} />
)
