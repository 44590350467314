import { useMemo, useRef } from 'react'

import { getEnumConverter } from '@jleem99/workinfo-models'

import { Enum } from 'utils/types'

export const useEnumChoices = (enumType: Enum) => {
	const enumConverter = useRef(getEnumConverter(enumType)).current
	const choices = useMemo(
		() =>
			Object.values(enumType).map((value) => ({
				id: value,
				name: enumConverter(value),
			})),
		[enumConverter, enumType],
	)

	return choices
}
