/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useCallback } from 'react'
import {
	List,
	Datagrid,
	ListProps,
	DateField,
	BooleanField,
	EditProps,
	Edit,
	SimpleForm,
	TextInput,
	BooleanInput,
	DateTimeInput,
	EditButton,
	NumberInput,
	ReferenceInput,
	SelectInput,
	TextField,
	CreateProps,
	Create,
	required,
	useTranslate,
	TitleProps,
	FormDataConsumer,
	Button,
} from 'react-admin'
import { useForm } from 'react-final-form'

import {
	EBusinessType,
	EJobPostingTag,
	EPayType,
	ERecruitGender,
	ERecruitQuantity,
	EVisaType,
	JobPostingDocument,
	validator,
	V_VisaType,
} from '@jleem99/workinfo-models'
import { makeStyles } from '@material-ui/core'

import { EnumField } from 'components/field/EnumField'
import { UserReferenceField } from 'components/field/UserReferenceField'
import { EnumArrayInput, EnumSelectInput } from 'components/input/EnumInput'
import { StorageImagesInput } from 'components/input/StorageImageInput'
import { UserReferenceInput } from 'components/input/UserReferenceInput'
import WorkDaysInput from 'components/input/WorkDaysInput'
import { useCommonStyles } from 'styles/commonStyles'
import { EMPTY_ARRAY } from 'utils'

const postFilters = [
	<TextInput key="q" source="q" label="검색" />,
	<ReferenceInput key="userId" source="userId" label="유저" reference="users" allowEmpty alwaysOn>
		<SelectInput optionText="name" />
	</ReferenceInput>,
]

export const JobPostingList = (props: ListProps) => (
	<List
		{...props}
		sort={{ field: 'updatedAt', order: 'DESC' }}
		// filters={postFilters}
		exporter={false}
	>
		<Datagrid className={useCommonStyles().header}>
			<DateField source="updatedAt" showTime className={useCommonStyles().textNoWrap} />

			<UserReferenceField />

			<TextField source="companyName" />
			<EnumField source="businessType" enumType={EBusinessType} />
			<TextField source="phoneNumber" />

			<TextField source="postTitle" />
			<EnumField source="recruitQuantity" enumType={ERecruitQuantity} />

			<BooleanField source="isEnded" />
			<BooleanField source="isPlusJobPosting" />

			<EditButton />
		</Datagrid>
	</List>
)

export const Title = ({ record }: TitleProps) => {
	const defaultTitle = useTranslate()('resources.jobPostings.name')
	return <span>{`${defaultTitle} "${record ? (record.postTitle as string) : ''}"`}</span>
}

const validateJobPosting = (values: JobPostingDocument) => {
	const errors: Partial<Record<keyof JobPostingDocument, any>> = {}

	if (!values.isWorktimeAdjustable) {
		const hhmmRegex = /^([0-1][0-9]|2[0-3])[0-5][0-9]$/
		if (!hhmmRegex.test(values.workStartTime))
			errors.workStartTime = { message: 'HHMM의 형식에 맞게 입력해주세요. (24h)' }
		if (!hhmmRegex.test(values.workEndTime))
			errors.workEndTime = { message: 'HHMM의 형식에 맞게 입력해주세요. (24h)' }
	}

	return errors
}

export const JobPostingEdit = (props: EditProps) => {
	return (
		<Edit {...props} title={<Title />}>
			<SimpleForm validate={validateJobPosting}>
				<TextInput source="id" disabled />
				<DateTimeInput source="updatedAt" validate={required()} />

				<UserReferenceInput source="userId" disabled />

				<TextInput source="companyName" validate={required()} />
				<EnumSelectInput source="businessType" enumType={EBusinessType} validate={required()} />
				<TextInput
					source="phoneNumber"
					validate={required()}
					format={validator.jobPosting.phoneNumber?.format}
					parse={validator.jobPosting.phoneNumber?.format}
				/>

				<TextInput source="postTitle" validate={required()} />
				<TextInput source="postBody" multiline validate={required()} fullWidth />
				<StorageImagesInput source="images" />

				<TextInput source="address" validate={required()} />
				<TextInput source="detailedAddress" validate={required()} />

				<OptionalInputs />

				<DateTimeInput source="recruitStartDate" validate={required()} />
				<DateTimeInput source="recruitEndDate" validate={required()} />
				<EnumSelectInput source="recruitGender" enumType={ERecruitGender} validate={required()} />
				<RecruitVisaTypesInput />
				<EnumSelectInput
					source="recruitQuantity"
					enumType={ERecruitQuantity}
					validate={required()}
				/>

				<EnumArrayInput source="tags" enumType={EJobPostingTag} />

				<BooleanInput source="isEnded" validate={required()} />
				<BooleanInput source="isPlusJobPosting" validate={required()} />
			</SimpleForm>
		</Edit>
	)
}

export const JobPostingCreate = (props: CreateProps) => {
	return (
		<Create {...props} title={<Title />}>
			<SimpleForm validate={validateJobPosting}>
				<DateTimeInput source="updatedAt" validate={required()} defaultValue={new Date()} />

				<UserReferenceInput source="userId" />

				<TextInput source="companyName" validate={required()} />
				<EnumSelectInput source="businessType" enumType={EBusinessType} validate={required()} />
				<TextInput
					source="phoneNumber"
					validate={required()}
					format={validator.jobPosting.phoneNumber?.format}
					parse={validator.jobPosting.phoneNumber?.format}
				/>

				<TextInput source="postTitle" validate={required()} />
				<TextInput source="postBody" multiline validate={required()} fullWidth />
				<StorageImagesInput source="images" />

				<TextInput source="address" validate={required()} />
				<TextInput source="detailedAddress" validate={required()} />

				<OptionalInputs />

				<DateTimeInput source="recruitStartDate" validate={required()} defaultValue={new Date()} />
				<DateTimeInput source="recruitEndDate" validate={required()} />
				<EnumSelectInput source="recruitGender" enumType={ERecruitGender} validate={required()} />
				<RecruitVisaTypesInput />
				<EnumSelectInput
					source="recruitQuantity"
					enumType={ERecruitQuantity}
					validate={required()}
				/>

				<EnumArrayInput source="tags" enumType={EJobPostingTag} />

				<BooleanInput source="isEnded" validate={required()} defaultValue={false} />
				<BooleanInput source="isPlusJobPosting" validate={required()} defaultValue={false} />
			</SimpleForm>
		</Create>
	)
}

const useInputStyles = makeStyles({
	optionalInputContainer: {
		marginTop: -16,
		marginBottom: 32,
		backgroundColor: '#f3f3f3',
		padding: '8px 16px',
	},
	optionalInput: {
		marginRight: 16,
		marginBottom: -16,
	},
})

const OptionalInputs = (props: any) => {
	const classes = useInputStyles()

	return (
		<React.Fragment>
			<BooleanInput
				source="isWorkDaysAdjustable"
				validate={required()}
				defaultValue={false}
				{...props}
			/>
			<FormDataConsumer {...props}>
				{({ formData, ...rest_ }) => {
					const rest = { ...rest_, className: classes.optionalInput }
					if (formData.isWorkDaysAdjustable) return null
					return (
						<div className={classes.optionalInputContainer}>
							<WorkDaysInput
								source="workDays"
								validate={required()}
								defaultValue={EMPTY_ARRAY}
								{...props}
							/>
						</div>
					)
				}}
			</FormDataConsumer>

			<BooleanInput
				source="isWorktimeAdjustable"
				validate={required()}
				defaultValue={true}
				{...props}
			/>
			<FormDataConsumer {...props}>
				{({ formData, ...rest_ }) => {
					const rest = { ...rest_, className: classes.optionalInput }
					if (formData.isWorktimeAdjustable) return null
					return (
						<div className={classes.optionalInputContainer}>
							<TextInput
								source="workStartTime"
								validate={required()}
								defaultValue="0000"
								{...rest}
							/>
							<TextInput source="workEndTime" validate={required()} defaultValue="0000" {...rest} />
						</div>
					)
				}}
			</FormDataConsumer>

			<BooleanInput source="isPayAdjustable" validate={required()} {...props} />
			<FormDataConsumer {...props}>
				{({ formData, ...rest_ }) => {
					const rest = { ...rest_, className: classes.optionalInput }
					if (formData.isPayAdjustable) return null
					return (
						<div className={classes.optionalInputContainer}>
							<EnumSelectInput
								source="payType"
								enumType={EPayType}
								validate={required()}
								defaultValue={EPayType.Monthly}
								{...rest}
							/>
							<NumberInput source="payAmount" validate={required()} defaultValue={0} {...rest} />
						</div>
					)
				}}
			</FormDataConsumer>
		</React.Fragment>
	)
}

const RecruitVisaTypesInput = (props: any) => {
	const form = useForm()
	const onClick = useCallback(() => {
		form.change('recruitVisaTypes', V_VisaType)
	}, [form])

	return (
		<React.Fragment>
			<EnumArrayInput
				source="recruitVisaTypes"
				enumType={EVisaType}
				validate={required()}
				{...props}
			/>
			<Button label="전체 선택" onClick={onClick} />
		</React.Fragment>
	)
}
