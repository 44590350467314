import { defaultTheme } from 'react-admin'

import { blue, indigo, pink, red } from '@material-ui/core/colors'
import merge from 'lodash/merge'

export const theme = merge({}, defaultTheme, {
	palette: {
		primary: indigo,
		secondary: pink,
		error: red,
		contrastThreshold: 3,
		tonalOffset: 0.2,
	},
	overrides: {
		MuiAppBar: {
			root: {
				backgroundColor: 'red',
			},
			colorSecondary: {
				backgroundColor: blue[800],
			},
		},
		MuiChip: {
			label: { fontWeight: 600, marginBottom: -1 },
		},
		MuiMenuItem: {
			root: {
				fontWeight: 500,
			},
		},
	},
})
