import {
	ArrayInput,
	Datagrid,
	DateField,
	DateTimeInput,
	Edit,
	EditButton,
	EditProps,
	FormTab,
	List,
	ListProps,
	NumberField,
	NumberInput,
	required,
	SimpleFormIterator,
	TabbedForm,
	TextField,
	TextInput,
} from 'react-admin'

import { UserReferenceField } from 'components/field/UserReferenceField'
import { ConsultFormInput } from 'components/input/ConsultFormInput'
import { useCommonStyles } from 'styles/commonStyles'

export const ConsultList = (props: ListProps) => (
	<List {...props} sort={{ field: 'submittedAt', order: 'DESC' }} exporter={false}>
		<Datagrid rowClick="edit">
			<DateField source="submittedAt" showTime className={useCommonStyles().textNoWrap} />

			<UserReferenceField />

			<TextField source="currentVisa" />
			<TextField source="applyVisa" />

			<NumberField source="progress.currentStepIndex" />

			<EditButton />
		</Datagrid>
	</List>
)

export const ConsultEdit = (props: EditProps) => (
	<Edit {...props}>
		<TabbedForm>
			<FormTab label="기본">
				<TextInput source="id" disabled />
				<DateTimeInput source="submittedAt" validate={required()} />
				<UserReferenceField />

				<TextInput source="currentVisa" disabled fullWidth />
				<TextInput source="applyVisa" disabled fullWidth />

				<NumberInput source="progress.currentStepIndex" validate={required()} />
				<ArrayInput source="progress.steps" validate={required()}>
					<SimpleFormIterator>
						<TextInput source="name" validate={required()} />
						<DateTimeInput source="processedAt" />
					</SimpleFormIterator>
				</ArrayInput>
			</FormTab>

			<FormTab label="기본정보 폼">
				<ConsultFormInput source="basicForm" />
			</FormTab>

			<FormTab label="추가정보 폼">
				<ConsultFormInput source="additionalForm" />
			</FormTab>
		</TabbedForm>
	</Edit>
)
