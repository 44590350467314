import { useState } from 'react'
import {
	ArrayInput,
	FieldProps,
	ImageInput,
	InputProps,
	required,
	SimpleFormIterator,
} from 'react-admin'
import { useForm } from 'react-final-form'

import { identity } from 'lodash'

import { InterleavedImage } from 'services/provider/classes/StorageHandler'
import { RARecord } from 'utils/types'

export const StorageImagesInput = (props: InputProps) => {
	const form = useForm()
	const field = form.getFieldState(props.source)
	if (!(field?.value instanceof Array)) field?.change([])

	return (
		<ArrayInput {...props}>
			<SimpleFormIterator>
				<StorageImageInput label="" source="" />
			</SimpleFormIterator>
		</ArrayInput>
	)
}

export const StorageImageInput = (props: InputProps) => {
	return (
		<ImageInput
			accept="image/*"
			format={identity} // disable ImageInput's default format
			parse={identity} // disable ImageInput's default parse
			validate={required()}
			{...props}
		>
			<UrlImageField />
		</ImageInput>
	)
}

const UrlImageField = (props: FieldProps<RARecord<InterleavedImage[number]>>) => {
	const [imgSrc, setImgSrc] = useState('')
	const { record } = props
	console.log({ ...props, imgSrc })

	if (record instanceof File) {
		if (!imgSrc.includes('blob')) setImgSrc(URL.createObjectURL(record))
	} else if (typeof record === 'string') {
		// https://stackoverflow.com/questions/57652176/react-hooks-usestate-setvalue-still-rerender-one-more-time-when-value-is-equal
		if (record !== imgSrc) setImgSrc(record)
	}

	return <img src={imgSrc} style={{ marginBottom: 20 }} height={300} />
}
