/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { CreateParams, CreateResult, UpdateParams, UpdateResult } from 'react-admin'

import { DefaultConverter, MetaCollectionType } from '@jleem99/workinfo-models'
import firebase from 'firebase'
import { omit } from 'lodash'

import { RARecord } from 'utils/types'

import ImageUploader from './ImageUploader'
import { NullableDataProvider } from './utils'

export type InterleavedImage = Array<File | string>

/**
 * 스토리지 관련 태스크 수행 클래스
 */
class StorageHandler implements NullableDataProvider {
	private FirestoreConverter = new DefaultConverter()

	public async create<RecordType extends RARecord = RARecord>(
		resource: string,
		params: CreateParams<RecordType>,
	): Promise<CreateResult<RecordType> | null> {
		const docRef = firebase.firestore().collection(resource).doc()
		const docData: RARecord = params.data
		let dirname: string // GCS prefix

		switch (resource) {
			case MetaCollectionType.Globals:
				dirname = `globals/${docRef.id}/banners`
				break

			case MetaCollectionType.CommunityPosts:
			case MetaCollectionType.JobPostings:
				dirname = `userContents/${docData.userId}/${resource}/${docRef.id}/images`
				break

			case MetaCollectionType.Magazines:
				dirname = `magazines/${docRef.id}/images`
				break

			default:
				return null
		}

		const hydratedDoc = await ImageUploader.uploadFilesFromDocument(docData, dirname)
		await docRef.set(omit(hydratedDoc, 'id'))
		return { data: { ...hydratedDoc, id: docRef.id } as RecordType }
	}

	public async update<RecordType extends RARecord = RARecord>(
		resource: string,
		params: UpdateParams<RecordType>,
	): Promise<UpdateResult<RecordType> | null> {
		const docRef = firebase.firestore().collection(resource).doc(params.id.toString())
		const docData: RARecord = params.data
		let dirname: string // GCS prefix

		switch (resource) {
			case MetaCollectionType.Globals:
				dirname = `globals/${docRef.id}/banners`
				break

			case MetaCollectionType.CommunityPosts:
			case MetaCollectionType.JobPostings:
				dirname = `userContents/${docData.userId}/${resource}/${docRef.id}/images`
				break

			case MetaCollectionType.Magazines:
				dirname = `magazines/${docRef.id}/images`
				break

			default:
				return null
		}

		const hydratedDoc = await ImageUploader.uploadFilesFromDocument(docData, dirname)
		await docRef.set(omit(hydratedDoc, 'id'))
		return { data: { ...hydratedDoc, id: docRef.id } as RecordType }
	}
}

export default new StorageHandler()
