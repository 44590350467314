import {
	DeleteParams,
	DeleteResult,
	GetManyReferenceParams,
	GetManyReferenceResult,
	Identifier,
} from 'react-admin'

import { DefaultConverter } from '@jleem99/workinfo-models'
import firebase from 'firebase'

import { RARecord } from 'utils/types'

import { NullableDataProvider } from './utils'

/**
 * 서브콜렉션 사용을 위한 핸들링 클래스
 *
 * react-admin에서 중첩(nested) 레코드를 지원하지 않기 때문에
 * 특수 표현법을 통해 서브콜렉션에 접근할 수 있도록 오버라이딩 해주어야 함
 *
 * 서브콜렉션 어댑터에서 핸들링되지 않을 시 (서브콜렉션 레코드가 아닐 시) `null`을 반환하며
 * 해당 경우 `customDataProvider`에서 `defaultDataProvider`로 fallback하게 됨
 */
class SubcollectionHandler implements NullableDataProvider {
	private FirestoreConverter = new DefaultConverter()

	public async getManyReference<RecordType extends RARecord = RARecord>(
		resource: string,
		params: GetManyReferenceParams,
	): Promise<GetManyReferenceResult<RecordType> | null> {
		console.log('SubcollectionAdaptor(getManyReference)', { resource, params })

		const { target, sort, pagination, id: parentId } = params
		const subcollection = this.parseSubcollection(resource, parentId)
		if (!subcollection) return null

		if (target === 'id') {
			let query = subcollection.limit(pagination.perPage)

			if (sort.field !== 'id')
				query = query.orderBy(sort.field, sort.order.toLowerCase() as 'desc' | 'asc')

			const snapshots = await query.get()

			return {
				data: snapshots.docs.map((snapshot) => snapshot.data() as RecordType),
				total: snapshots.size,
			}
		} else {
			throw new Error('Not yet implemented')
		}
	}

	public async delete<RecordType extends RARecord = RARecord>(
		resource: string,
		params: DeleteParams,
	): Promise<DeleteResult<RecordType> | null> {
		console.log('SubcollectionAdaptor(delete)', { resource, params })

		const [collectionId, subCollectionId] = this.parseResource(resource)
		if (!subCollectionId) return null

		// delete operation에서는 부모 도큐먼트 ID를 넘겨줄 방법이 없음
		// 대안으로 window.location.href에서 파싱
		const parentId = new RegExp(`${collectionId}/([^/]+)`).exec(window.location.href)?.[1]
		if (!parentId) throw new Error("Couldn't get parentId for subcollection")
		console.log({ parentId })

		const subcollection = this.parseSubcollection(resource, parentId)
		await subcollection?.doc(params.id.toString()).delete()

		return { data: params.previousData as RecordType }
	}

	/**
	 * 서브콜렉션 리소스 이름 파싱 메소드
	 */
	private parseResource(resource: string) {
		const [_, collectionId, subcollectionId] = /^(.*?)(?::(.*))?$/.exec(resource) ?? []
		// console.log({ collectionId, subcollectionId })
		return [collectionId, subcollectionId]
	}

	/**
	 * 서브콜렉션 레퍼런스 파싱
	 *
	 * @param resource 서브콜렉션을 참조하기 위해서는 {부모콜렉션 이름}:{서브콜렉션 이름}과 같이 사용
	 * @param parentId 서브콜렉션을 포함하고 있는 부모 도큐먼트의 ID
	 */
	private parseSubcollection(resource: string, parentId: Identifier) {
		const [collectionId, subcollectionId] = this.parseResource(resource)
		if (!subcollectionId) return null

		return firebase
			.firestore()
			.collection(`${collectionId}/${parentId}/${subcollectionId}`)
			.withConverter(this.FirestoreConverter)
	}
}

export default new SubcollectionHandler()
