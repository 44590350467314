import { useRef } from 'react'
import {
	ArrayField,
	ArrayFieldProps,
	ChipFieldProps,
	FunctionField,
	SingleFieldList,
	useRecordContext,
} from 'react-admin'

import { Enum, getEnumConverter } from '@jleem99/workinfo-models'
import { get } from 'lodash'

import { RawChipField } from './RawChipField'

export interface EnumFieldProps {
	enumType: Enum
	enumConverter?: (value: string) => string
}

export const EnumField = ({
	enumType,
	enumConverter: enumConverterProp,
	...props
}: ChipFieldProps & EnumFieldProps) => {
	const { className, source, emptyText, ...rest } = props
	const record = useRecordContext(props)
	const rawValue = source ? (get(record, source) as unknown) : ''

	const defaultEnumConverter = useRef(getEnumConverter(enumType)).current
	const enumConverter = enumConverterProp ?? defaultEnumConverter
	const value = typeof rawValue === 'string' ? enumConverter(rawValue) : rawValue

	return <RawChipField {...props} value={value} />
}

export const EnumArrayField = ({
	enumType,
	enumConverter: enumConverterProp,
	...props
}: Omit<ArrayFieldProps, 'children'> & EnumFieldProps) => {
	const defaultEnumConverter = useRef(getEnumConverter(enumType)).current
	const enumConverter = enumConverterProp ?? defaultEnumConverter

	return (
		<ArrayField {...props} sortable={false}>
			<SingleFieldList linkType={false}>
				<FunctionField
					render={(record: any, source?: string) => {
						return <RawChipField value={enumConverter(record as string)} />
					}}
				/>
			</SingleFieldList>
		</ArrayField>
	)
}
