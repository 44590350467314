import {
	Create,
	Datagrid,
	DateField,
	DateTimeInput,
	DeleteButton,
	Edit,
	EditButton,
	EditProps,
	FormTab,
	List,
	ListProps,
	NumberField,
	NumberInput,
	required,
	SimpleForm,
	TabbedForm,
	TextField,
	TextInput,
} from 'react-admin'

import { ECommunityPostCategory } from '@jleem99/workinfo-models'
import { CreateProps } from 'ra-core/esm/controller/details/useCreateController'

import { EnumField } from 'components/field/EnumField'
import { SubcollectionReferenceManyField } from 'components/field/SubcollectionReferenceField'
import { UserReferenceField } from 'components/field/UserReferenceField'
import { EnumSelectInput } from 'components/input/EnumInput'
import { StorageImagesInput } from 'components/input/StorageImageInput'
import {
	UserAndUsernameReferenceInput,
	UserReferenceInput,
} from 'components/input/UserReferenceInput'
import { useCommonStyles } from 'styles/commonStyles'

export const CommunityPostList = (props: ListProps) => (
	<List {...props} sort={{ field: 'createdAt', order: 'DESC' }} exporter={false}>
		<Datagrid className={useCommonStyles().header}>
			<DateField source="createdAt" showTime className={useCommonStyles().textNoWrap} />

			<UserReferenceField />
			{/* <TextField source="username" /> // Denormalized Field */}

			<EnumField source="category" enumType={ECommunityPostCategory} />
			<TextField source="body" className={useCommonStyles().textWrap} />

			<NumberField source="likeCount" />
			<NumberField source="commentCount" />

			<EditButton />
			{/* <DeleteButton /> */}
		</Datagrid>
	</List>
)

export const CommunityPostEdit = (props: EditProps) => (
	<Edit {...props}>
		<TabbedForm>
			<FormTab label="포스트">
				<TextInput source="id" disabled />
				<DateTimeInput source="createdAt" validate={required()} />
				<UserReferenceInput source="userId" disabled />
				{/* <TextInput source="username" /> // Denormalized Field */}
				<EnumSelectInput
					source="category"
					enumType={ECommunityPostCategory}
					validate={required()}
				/>
				<TextInput source="body" multiline validate={required()} fullWidth />
				<StorageImagesInput source="images" />
				<NumberInput source="likeCount" validate={required()} disabled />
				<NumberInput source="commentCount" validate={required()} disabled />
			</FormTab>

			<FormTab label="resources.communityPosts:comments.name">
				<SubcollectionReferenceManyField
					addLabel={false}
					subcollectionId="comments"
					target="id"
					sort={{ field: 'createdAt', order: 'DESC' }}
				>
					<Datagrid>
						<DateField source="createdAt" showTime />
						<UserReferenceField />
						<TextField source="body" />
						<NumberField source="likeCount" />
						<DeleteButton redirect={false} />
					</Datagrid>
				</SubcollectionReferenceManyField>
			</FormTab>
		</TabbedForm>
	</Edit>
)

export const CommunityPostCreate = (props: CreateProps) => {
	return (
		<Create {...props}>
			<SimpleForm>
				<DateTimeInput source="createdAt" validate={required()} defaultValue={new Date()} />

				<UserAndUsernameReferenceInput />

				<EnumSelectInput
					source="category"
					enumType={ECommunityPostCategory}
					validate={required()}
				/>
				<TextInput source="body" multiline validate={required()} fullWidth />
				<StorageImagesInput source="images" />

				<NumberInput source="likeCount" validate={required()} defaultValue={0} disabled />
				<NumberInput source="commentCount" validate={required()} defaultValue={0} disabled />
			</SimpleForm>
		</Create>
	)
}
