import {
	ArrayInput,
	ArrayInputProps,
	FieldTitle,
	InputProps,
	isRequired,
	required,
	SimpleFormIterator,
	TextInput,
} from 'react-admin'

import { InputLabel, makeStyles } from '@material-ui/core'

import { StorageImageInput } from './StorageImageInput'

export const SwipeBannerInput = (props: Omit<ArrayInputProps, 'children'> & { source: string }) => (
	<ArrayInput {...props} validate={required()}>
		<SimpleFormIterator>
			<StorageImageInput source="imgSrc" validate={required()} />
			<TextInput source="url" />
		</SimpleFormIterator>
	</ArrayInput>
)

export const StaticBannerInput = ({
	source,
	disableLink,
	...props
}: InputProps & { disableLink?: boolean }) => {
	const { label, resource, validate } = props
	const classes = useStyles()

	return (
		<div className={classes.staticBannerInput}>
			<InputLabel htmlFor={source} shrink>
				<FieldTitle
					label={label as string}
					source={source}
					resource={resource}
					isRequired={isRequired(validate)}
				/>
			</InputLabel>
			<StorageImageInput
				source={`${source}.imgSrc`}
				label="스태틱 배너 이미지"
				{...props}
				validate={required()}
			/>
			{!disableLink && <TextInput source={`${source}.url`} label="스태틱 배너 링크" {...props} />}
		</div>
	)
}

const useStyles = makeStyles({
	staticBannerInput: {
		marginTop: 30,
		marginBottom: 30,
	},
})
