import React from 'react'
import {
	ArrayInput,
	Create,
	CreateProps,
	Datagrid,
	DateField,
	DateTimeInput,
	Edit,
	EditButton,
	EditProps,
	InputProps,
	List,
	ListProps,
	required,
	SimpleForm,
	SimpleFormIterator,
	TextInput,
} from 'react-admin'

import { StaticBannerInput, SwipeBannerInput } from 'components/input/BannerInput'
import { StorageImageInput } from 'components/input/StorageImageInput'
import { useCommonStyles } from 'styles/commonStyles'

export const GlobalList = (props: ListProps) => (
	<List {...props} sort={{ field: 'createdAt', order: 'DESC' }} exporter={false}>
		<Datagrid className={useCommonStyles().header}>
			<DateField source="createdAt" showTime className={useCommonStyles().textNoWrap} />

			<EditButton />
		</Datagrid>
	</List>
)

export const GlobalEdit = (props: EditProps) => (
	<Edit {...props}>
		<SimpleForm>
			<TextInput source="id" disabled />

			<DateTimeInput source="createdAt" validate={required()} />

			<SwipeBannerInput source="banner.mainSwipeBanner" />
			<StaticBannerInput source="banner.mainStaticBanner" />
			<SwipeBannerInput source="banner.recruitSwipeBanner" />
			<StaticBannerInput source="banner.communityBanner" disableLink />
			<SwipeBannerInput source="banner.mypageSwipeBanner" />

			<PollConfigInput />
		</SimpleForm>
	</Edit>
)

export const GlobalCreate = (props: CreateProps) => (
	<Create {...props}>
		<SimpleForm>
			<DateTimeInput source="createdAt" validate={required()} defaultValue={new Date()} />

			<SwipeBannerInput source="banner.mainSwipeBanner" />
			<StaticBannerInput source="banner.mainStaticBanner" />
			<SwipeBannerInput source="banner.recruitSwipeBanner" />
			<StaticBannerInput source="banner.communityBanner" disableLink />
			<SwipeBannerInput source="banner.mypageSwipeBanner" />

			<PollConfigInput />
		</SimpleForm>
	</Create>
)

const pollGradientValidator = [
	required(),
	(gradient: any[]) => {
		if (gradient.length !== 2) return '색상 2개를 입력해주세요.'
	},
]
const hexValidator = [
	(color: any) => {
		if (typeof color !== 'string' || !/^#[0-f]{6}$/.test(color))
			return '올바른 hex 값을 입력해주세요. (e.g. #ffffff)'
	},
]

const PollConfigInput = (props: Omit<InputProps, 'source'>) => (
	<React.Fragment>
		<ArrayInput source="poll.gradient" {...props} validate={pollGradientValidator}>
			<SimpleFormIterator>
				<TextInput label="HEX 코드" source="" validate={hexValidator} />
			</SimpleFormIterator>
		</ArrayInput>
		<StorageImageInput source="poll.backgroundImage" {...props} />
	</React.Fragment>
)
