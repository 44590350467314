import { SelectInput, SelectInputProps } from 'react-admin'

import i18n_countries, { Alpha2Code } from 'i18n-iso-countries'
import i18n_countries_locale_kr from 'i18n-iso-countries/langs/ko.json'
i18n_countries.registerLocale(i18n_countries_locale_kr)

const alpha2Codes = Object.keys(i18n_countries.getAlpha2Codes()) as Alpha2Code[]
const choices = alpha2Codes.map((alpha2Code) => ({
	id: alpha2Code,
	name: i18n_countries.getName(alpha2Code, 'ko'),
}))

const NationalityInput = (props: SelectInputProps) => <SelectInput choices={choices} {...props} />

export default NationalityInput
