import {
	List,
	Datagrid,
	ListProps,
	DateField,
	BooleanField,
	EditProps,
	Edit,
	SimpleForm,
	TextInput,
	BooleanInput,
	EditButton,
	TextField,
	DateTimeInput,
	required,
	ToolbarProps,
	Toolbar,
	SaveButton,
} from 'react-admin'

import { EGender, EVisaType } from '@jleem99/workinfo-models'

import { EnumField } from 'components/field/EnumField'
import { NationalityField } from 'components/field/NationalityField'
import { EnumSelectInput } from 'components/input/EnumInput'
import NationalityInput from 'components/input/NationalityInput'
import { useCommonStyles } from 'styles/commonStyles'

export const UserList = (props: ListProps) => (
	<List
		{...props}
		sort={{ field: 'createdAt', order: 'DESC' }}
		exporter={false}
		bulkActionButtons={false}
	>
		<Datagrid className={useCommonStyles().header}>
			<TextField source="id" />
			<DateField source="createdAt" showTime className={useCommonStyles().textNoWrap} />
			<TextField source="name" />
			<EnumField source="gender" enumType={EGender} />
			<NationalityField source="nationality" />
			<EnumField source="currentVisa" enumType={EVisaType} />
			<DateField source="currentVisaExpiresAt" />
			<BooleanField source="hasAgreedToMarketing" />
			<BooleanField source="hasAgreedToPushNotification" />
			<EditButton />
		</Datagrid>
	</List>
)

export const UserEdit = (props: EditProps) => (
	<Edit {...props}>
		<SimpleForm>
			<TextInput source="id" disabled />
			<DateTimeInput source="birthAt" />
			<TextInput source="name" validate={required()} />
			<EnumSelectInput source="gender" enumType={EGender} />
			<NationalityInput source="nationality" />
			<EnumSelectInput source="currentVisa" enumType={EVisaType} />
			<DateTimeInput source="currentVisaExpiresAt" />
			<TextInput source="address" />
			<TextInput source="phoneNumber" />
			<BooleanInput source="hasAgreedToMarketing" validate={required()} />
			<BooleanInput source="hasAgreedToPushNotification" validate={required()} />
			<DateTimeInput source="createdAt" disabled />
			<TextInput source="pushToken.data" disabled />
		</SimpleForm>
	</Edit>
)

const UserEditToolbar = (props: ToolbarProps) => (
	<Toolbar {...props}>
		<SaveButton />
	</Toolbar>
)
