import {
	CreateParams,
	CreateResult,
	DataProvider,
	DeleteParams,
	DeleteResult,
	GetManyReferenceParams,
	GetManyReferenceResult,
	UpdateParams,
	UpdateResult,
} from 'react-admin'

import { JobPostingDocument, toNormalizedRegionId } from '@jleem99/workinfo-models'

import { RARecord } from 'utils/types'

import StorageHandler from './classes/StorageHandler'
import SubcollectionHandler from './classes/SubcollectionHandler'
import { defaultDataProvider } from './defaultProvider'

export const customDataProvider: DataProvider = {
	...defaultDataProvider,

	async getManyReference<RecordType extends RARecord = RARecord>(
		resource: string,
		params: GetManyReferenceParams,
	): Promise<GetManyReferenceResult<RecordType>> {
		return (
			(await SubcollectionHandler.getManyReference<RecordType>(resource, params)) ??
			(await defaultDataProvider.getManyReference<RecordType>(resource, params))
		)
	},

	async delete<RecordType extends RARecord = RARecord>(
		resource: string,
		params: DeleteParams,
	): Promise<DeleteResult<RecordType>> {
		return (
			(await SubcollectionHandler.delete<RecordType>(resource, params)) ??
			(await defaultDataProvider.delete<RecordType>(resource, params))
		)
	},

	async create<RecordType extends RARecord = RARecord>(
		resource: string,
		params: CreateParams<RecordType>,
	): Promise<CreateResult<RecordType>> {
		handleJobPostingRegion(resource, params)
		return (
			(await StorageHandler.create<RecordType>(resource, params)) ??
			(await defaultDataProvider.create<RecordType>(resource, params))
		)
	},

	async update<RecordType extends RARecord = RARecord>(
		resource: string,
		params: UpdateParams<RecordType>,
	): Promise<UpdateResult<RecordType>> {
		handleJobPostingRegion(resource, params)
		return (
			(await StorageHandler.update<RecordType>(resource, params)) ??
			(await defaultDataProvider.update<RecordType>(resource, params))
		)
	},
}

const handleJobPostingRegion = (
	resource: string,
	params: CreateParams<any> | UpdateParams<any>,
) => {
	if (resource === 'jobPostings') {
		const doc = params.data as JobPostingDocument
		const [depth1, depth2] = doc.address.split(' ')
		doc.normalizedRegion = toNormalizedRegionId({ depth1, depth2 })
		doc.normalizedRegionDepth1 = doc.normalizedRegion.split('_')[0]
	}
}
