/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useEffect, useState } from 'react'
import {
	ChipField,
	Datagrid,
	DateField,
	FieldProps,
	List,
	ListProps,
	ReferenceField,
	TextField,
} from 'react-admin'

import {
	ECommunityReportType,
	EJobPostingReportType,
	EPostType,
	ReportDocument,
	toCommunityReportTypeString,
	toJobPostingReportTypeString,
} from '@jleem99/workinfo-models'
import firebase from 'firebase'

import { EnumField } from 'components/field/EnumField'
import { RawTextField } from 'components/field/RawTextField'
import { useCommonStyles } from 'styles/commonStyles'

const EReportTypeAll = { ...EJobPostingReportType, ...ECommunityReportType }
type EReportTypeAll = EJobPostingReportType | ECommunityReportType
const reportTypeEnumConverter = (value: string) =>
	toJobPostingReportTypeString(value as EJobPostingReportType) ||
	toCommunityReportTypeString(value as ECommunityReportType)

export const ReportList = (props: ListProps) => (
	<List {...props} sort={{ field: 'reportedAt', order: 'DESC' }} exporter={false}>
		<Datagrid className={useCommonStyles().header}>
			<DateField source="reportedAt" showTime className={useCommonStyles().textNoWrap} />

			<ReferenceField source="reportedUserId" reference="users">
				<ChipField source="name" />
			</ReferenceField>

			<EnumField source="postType" enumType={EPostType} />
			<EnumField
				source="reportType"
				enumType={EReportTypeAll}
				enumConverter={reportTypeEnumConverter}
			/>

			<PostReferenceField label="포스트/댓글" />
		</Datagrid>
	</List>
)

const postTypeToReference = {
	[EPostType.JobPosting]: 'jobPostings',
	[EPostType.CommunityPost]: 'communityPosts',
	[EPostType.CommunityComment]: 'communityPosts',
	[EPostType.PollComment]: 'polls',
}

const PostReferenceField = (props: FieldProps) => {
	const record = props.record as ReportDocument
	const reference = postTypeToReference[record.postType]
	const referenceSource = record.postType === EPostType.JobPosting ? 'postBody' : 'body'

	const shouldFetchComment =
		record.postType === EPostType.CommunityComment || record.postType === EPostType.PollComment
	const [commentBody, setCommentBody] = useState('')
	useEffect(() => {
		if (shouldFetchComment) {
			console.info('Fetching comment...', props)
			const { postId, commentId } = record
			void firebase
				.firestore()
				.doc(`${reference}/${postId}/comments/${commentId}`)
				.get()
				.then((snapshot) => snapshot.data())
				.then((data) => data && setCommentBody(data.body as string))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	if (shouldFetchComment)
		return (
			<ReferenceField source="postId" reference={reference} {...props}>
				<RawTextField value={commentBody} />
			</ReferenceField>
		)

	return (
		<ReferenceField source="postId" reference={reference} {...props}>
			<TextField source={referenceSource} />
		</ReferenceField>
	)
}
